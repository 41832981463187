import React from 'react';
import { Box } from '@landr/maestro';
import {
    NetworkOutlined,
    PlayFilled,
    CaretUpOutlined,
    CaretDownOutlined,
    HeadphonesOutlined,
} from '@landr/maestro';

const supportedIcons = (icon: string): JSX.Element | null => {
    switch (icon) {
        case 'Selector':
        case 'NetworkOutlined':
            return <NetworkOutlined />;
        case 'PlayFilled':
            return <PlayFilled />;
        case 'CaretUpOutlined':
            return <CaretUpOutlined />;
        case 'CaretDownOutlined':
            return <CaretDownOutlined />;
        case 'HeadphonesOutlined':
            return <HeadphonesOutlined />;
        default:
            return null;
    }
};

/**
 * Using a regex to find the shortcode syntax [icon:Name] and
 * convert into a wrapped icon component if the shortcode key is supported
 * @param stringToParse
 */
export function getIconFromShortcode(
    stringToParse: string,
): Array<string | JSX.Element> {
    // Parsing [icon:Name]
    const parsedString: Array<string | JSX.Element> = stringToParse.split(
        /\[icon:\s*(.[a-zA-Z]*)\]/gi,
    );

    for (let i = 1; i < parsedString.length; i += 2) {
        parsedString[i] = (
            <Box
                as="span"
                lineHeight="xs"
                css={{
                    '& svg': {
                        verticalAlign: 'middle',
                    },
                }}
                key={i}
            >
                {supportedIcons(parsedString[i] as string) ?? null}
            </Box>
        );
    }

    return parsedString;
}
