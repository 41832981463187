/* eslint-disable */
/**
 * !!!DO NOT EDIT THIS FILE!!!
 *
 * The following code is fully generated from the definitions provided in the Analytics AirTable definition.
 * You can regenerate it with "yarn generate:analytics:v2"
 */
import { AbstractSegmentServiceV2, AnalyticsApplication, AnalyticsPlatformName, SegmentWindow } from '@landr/core';

export enum AssetCategory {
    AICoverArt = 'AI Cover Art',
    Artwork = 'Artwork',
    Audio = 'Audio',
    Collection = 'Collection',
    CoverPhoto = 'Cover Photo',
    CreatorTrack = 'Creator Track',
    Demo = 'Demo',
    Image = 'Image',
    MasteredAlbum = 'Mastered Album',
    MasteredTrack = 'Mastered Track',
    NetworkProfile = 'Network Profile',
    OriginalAlbum = 'Original Album',
    OriginalTrack = 'Original Track',
    Other = 'Other',
    Plugin = 'Plugin',
    ProcessedSample = 'Processed Sample',
    ProfilePicture = 'Profile Picture',
    ReferenceTrack = 'Reference Track',
    ReHance = 'ReHance',
    RevisedMasteredTrack = 'Revised Mastered Track',
    Sample = 'Sample',
    SamplePack = 'Sample Pack',
    SampleRepack = 'Sample Repack',
    Set = 'Set',
    SharedFile = 'Shared File',
    Spreadsheet = 'Spreadsheet',
    Stem = 'Stem',
    Text = 'Text',
    Track = 'Track',
    Video = 'Video',
    VideoMessage = 'Video Message',
}

export enum AssetCategoryGroup {
    Audio = 'Audio',
    Image = 'Image',
    Plugin = 'Plugin',
    Users = 'Users',
    Video = 'Video',
}

export enum AssetCreationScenario {
    AlbumCreated = 'Album Created',
    CollectionCreated = 'Collection Created',
    FirstFreeMobileMaster = 'First Free Mobile Master',
    MasterCreated = 'Master Created',
    NewFormatCreated = 'New Format Created',
}

export enum AssetMasterType {
    Master = 'Master',
    NotMastered = 'Not Mastered',
    Revision = 'Revision',
}

export enum CtaGoal {
    AccessCoursePack = 'Access Course Pack',
    AccessMusixmatch = 'Access Musixmatch',
    ActivatePlan = 'Activate Plan',
    AddClients = 'Add Clients',
    AddtoCalendarGoogle = 'Add to Calendar - Google',
    AddtoCalendariCal = 'Add to Calendar - iCal',
    AddtoCalendarOutlook = 'Add to Calendar - Outlook',
    AllowFileDownload = 'Allow File Download',
    BacktoPreviousStep = 'Back to Previous Step',
    BrowseFreeSamples = 'Browse Free Samples',
    BrowseNetwork = 'Browse Network',
    BrowsePros = 'Browse Pros',
    BrowseSamples = 'Browse Samples',
    BrowseServiceCategory = 'Browse Service Category',
    BrowseTopService = 'Browse Top Service',
    BuySamples1000Credits = 'Buy Samples 1000 Credits',
    BuyUnlimitedMP3 = 'Buy Unlimited MP3',
    Checkout = 'Checkout',
    ChooseFormat = 'Choose Format',
    CompleteProfileOver50Percent = 'Complete Profile Over 50 Percent',
    CopyLink = 'Copy Link',
    CopyPromolink = 'Copy Promolink',
    CreateProfile = 'Create Profile',
    CreateQuote = 'Create Quote',
    DiscoverSAArtist = 'Discover SA Artist',
    Download3rdPartyPlugin = 'Download 3rd Party Plugin',
    DownloadPluginLandrChromatic = 'Download Plugin - Landr Chromatic',
    DownloadPluginLandrDesktopApp = 'Download Plugin - Landr Desktop App',
    DownloadPluginLandrFXAccoustic = 'Download Plugin - Landr FX Accoustic',
    DownloadPluginLandrFXBass = 'Download Plugin - Landr FX Bass',
    DownloadPluginLandrFXBeats = 'Download Plugin - Landr FX Beats',
    DownloadPluginLandrFXElectric = 'Download Plugin - Landr FX Electric',
    DownloadPluginLandrFXVoice = 'Download Plugin - Landr FX Voice',
    DownloadPluginLandrGuitar = 'Download Plugin - Landr Guitar',
    DownloadPluginLandrRePitchElements = 'Download Plugin - Landr RePitch Elements',
    DownloadPluginLandrSamples = 'Download Plugin - Landr Samples',
    DownloadPluginLandrSessions = 'Download Plugin - Landr Sessions',
    DownloadPluginLethal = 'Download Plugin - Lethal',
    DownloadSample = 'Download Sample',
    DownloadVocAlign = 'Download VocAlign',
    FavouriteSample = 'Favourite Sample',
    FindaPro = 'Find a Pro',
    GetALC = 'Get ALC',
    GetDeal = 'Get Deal',
    GetMixFeedback = 'Get Mix Feedback',
    GetNewFormat = 'Get New Format',
    GetTier1Plan = 'Get Tier 1 Plan',
    GetTier2Plan = 'Get Tier 2 Plan',
    GetTier3Plan = 'Get Tier 3 Plan',
    GotoAuthentication = 'Go to Authentication',
    GotoChromatic = 'Go to Chromatic',
    GotoCommunityChannels = 'Go to Community Channels',
    GotoCourses = 'Go to Courses',
    GotoCreator = 'Go to Creator',
    GotoDistribution = 'Go to Distribution',
    GotoLibrary = 'Go to Library',
    GotoMessages = 'Go to Messages',
    GotoNetwork = 'Go to Network',
    GotoPlugins = 'Go to Plugins',
    GotoProjects = 'Go to Projects',
    GotoReleases = 'Go to Releases',
    GotoSamples = 'Go to Samples',
    GotoSessions = 'Go to Sessions',
    GotoStripeBilling = 'Go to Stripe Billing',
    InstallPluginLandrChromatic = 'Install Plugin - Landr Chromatic',
    InstallPluginLandrDesktopApp = 'Install Plugin - Landr Desktop App',
    InstallPluginLandrFXAccoustic = 'Install Plugin - Landr FX Accoustic',
    InstallPluginLandrFXBass = 'Install Plugin - Landr FX Bass',
    InstallPluginLandrFXBeats = 'Install Plugin - Landr FX Beats',
    InstallPluginLandrFXElectric = 'Install Plugin - Landr FX Electric',
    InstallPluginLandrFXSuite = 'Install Plugin - Landr FX Suite',
    InstallPluginLandrFXVoice = 'Install Plugin - Landr FX Voice',
    InstallPluginLandrGuitar = 'Install Plugin - Landr Guitar',
    InstallPluginLandrRePitchElements = 'Install Plugin - Landr RePitch Elements',
    InstallPluginLandrSamples = 'Install Plugin - Landr Samples',
    InstallPluginLandrSessions = 'Install Plugin - Landr Sessions',
    InstallPluginLethal = 'Install Plugin - Lethal',
    JoinSession = 'Join Session',
    LearnaboutChromatic = 'Learn about Chromatic',
    LearnaboutSessions = 'Learn about Sessions',
    LogIn = 'Log In',
    NextStep = 'Next Step',
    OpeninCreator = 'Open in Creator',
    OpenSharingDrawer = 'Open Sharing Drawer',
    RedeemLicence = 'Redeem Licence',
    ReviewOrder = 'Review Order',
    SearchNetwork = 'Search Network',
    ShowMore = 'Show More',
    SignUp = 'Sign Up',
    SkipStep = 'Skip Step',
    StartNewMessage = 'Start New Message',
    StartTrial = 'Start Trial',
    StartVocAlign = 'Start VocAlign',
    SubscribetoSAArtist = 'Subscribe to SA Artist',
    ToggleOffAutoTrigger = 'Toggle Off Auto-Trigger',
    ToggleOnAutoTrigger = 'Toggle On Auto-Trigger',
    TryCreator = 'Try Creator',
    TurnOffAutoPlay = 'Turn Off Auto-Play',
    TurnOnAutoPlay = 'Turn On Auto-Play',
    UpgradeALC = 'Upgrade ALC',
    UpgradeDistributionPlan = 'Upgrade Distribution Plan',
    UpgradeMasteringPlan = 'Upgrade Mastering Plan',
    UpgradePlan = 'Upgrade Plan',
    UseABPlayer = 'Use AB Player',
    ViewAAPPricing = 'View AAP Pricing',
    ViewAdvancedMasterSettings = 'View Advanced Master Settings',
    ViewALCPricing = 'View ALC Pricing',
    ViewAutoTunePlugin = 'View Auto-Tune Plugin',
    ViewBeeperPlugin = 'View Beeper Plugin',
    ViewBlueCatFlangerPlugin = 'View Blue Cat Flanger Plugin',
    ViewBoogexPlugin = 'View Boogex Plugin',
    ViewCourse = 'View Course',
    ViewDesktopAppLandingPage = 'View Desktop App Landing Page',
    ViewDetail = 'View Detail',
    ViewDistributionTrends = 'View Distribution Trends',
    ViewDistrokidComparison = 'View Distrokid Comparison',
    ViewLandrFXBundle = 'View Landr FX Bundle',
    ViewLandrFXPlugin = 'View Landr FX Plugin',
    ViewLatencyPlugin = 'View Latency Plugin',
    ViewLethalPlugin = 'View Lethal Plugin',
    ViewMessages = 'View Messages',
    ViewNewDropdown = 'View New Dropdown',
    ViewNotifications = 'View Notifications',
    ViewOldSkoolVerbPlusPlugin = 'View OldSkoolVerb Plus Plugin',
    ViewOvertoneGEQPlugin = 'View Overtone GEQ Plugin',
    ViewPanCakePlugin = 'View PanCake Plugin',
    ViewPlugin = 'View Plugin',
    ViewPricing = 'View Pricing',
    ViewProject = 'View Project',
    ViewPromoCard = 'View Promo Card',
    ViewPromolink = 'View Promolink',
    ViewRecorderPlugin = 'View Recorder Plugin',
    ViewReferralRewards = 'View Referral Rewards',
    ViewSamplesPlugin = 'View Samples Plugin',
    ViewServices = 'View Services',
    ViewSessionsPlugin = 'View Sessions Plugin',
    ViewSessionsShortcut = 'View Sessions Shortcut',
    ViewSpanPlugin = 'View Span Plugin',
    ViewStereoTouchPlugin = 'View Stereo Touch Plugin',
    ViewStudioYearlyCourses = 'View Studio Yearly Courses',
    ViewTripleEQ4Plugin = 'View Triple EQ 4 Plugin',
    WaitzineLinkClick = 'Waitzine Link Click',
    WatchReferenceMasteringVideo = 'Watch Reference Mastering Video',
}

export enum CtaType {
    Button = 'Button',
    Card = 'Card',
    Dropdown = 'Dropdown',
    EllipsisMenu = 'Ellipsis Menu',
    PlainText = 'Plain Text',
    Toggle = 'Toggle',
}

export enum InteractionSource {
    AddAction = 'Add Action',
    AssetAction = 'Asset Action',
    AssetActionDropdown = 'Asset Action - Dropdown',
    AssetActionFeed = 'Asset Action - Feed',
    AssetActionFile = 'Asset Action - File',
    AssetActionMain = 'Asset Action - Main',
    AuthenticationScreen = 'Authentication Screen',
    Automatic = 'Automatic',
    Banner = 'Banner',
    BellNotification = 'Bell Notification',
    BounceFolder = 'Bounce Folder',
    CalloutBanner = 'Callout Banner',
    CancelConfirmationModal = 'Cancel Confirmation Modal',
    CancelFeatureLossModal = 'Cancel Feature Loss Modal',
    CancelOptions = 'Cancel Options',
    CancelReasonModal = 'Cancel Reason Modal',
    CardAction = 'Card Action',
    CategoryTitle = 'Category Title',
    ChooseFormatModal = 'Choose Format Modal',
    CommentAction = 'Comment Action',
    CompareModal = 'Compare Modal',
    CreatorAction = 'Creator Action',
    CreatorBanner = 'Creator Banner',
    DashboardCard = 'Dashboard Card',
    Direct = 'Direct',
    DownloadAllAction = 'Download All Action',
    DragandDrop = 'Drag and Drop',
    Email = 'Email',
    EmptyState = 'Empty State',
    FreeTrialCard = 'Free Trial Card',
    HeroBanner = 'Hero Banner',
    InvitationModal = 'Invitation Modal',
    LibraryAction = 'Library Action',
    LibraryEmptyState = 'Library Empty State',
    LibraryTray = 'Library Tray',
    MainAction = 'Main Action',
    MasterFormatModal = 'Master Format Modal',
    MasterPreviewModal = 'Master Preview Modal',
    MasterWaitzine = 'Master Waitzine',
    MasterWaitzineSlide1 = 'Master Waitzine - Slide 1',
    MasterWaitzineSlide2 = 'Master Waitzine - Slide 2',
    MasterWaitzineSlide3 = 'Master Waitzine - Slide 3',
    MasterWaitzineSlide4 = 'Master Waitzine - Slide 4',
    MasterWaitzineSlide5 = 'Master Waitzine - Slide 5',
    MasterWaitzineSlide6 = 'Master Waitzine - Slide 6',
    MessagesAction = 'Messages Action',
    MessagesDropdown = 'Messages Dropdown',
    MobileAppSettings = 'Mobile App Settings',
    MobileNav = 'Mobile Nav',
    MultiselectAction = 'Multiselect Action',
    Navbar = 'Navbar',
    NewAction = 'New Action',
    NewActionAlbumMaster = 'New Action - Album Master',
    NewActionMaster = 'New Action - Master',
    NewActionReHance = 'New Action - ReHance',
    NewActionStems = 'New Action - Stems',
    NewActionUpload = 'New Action - Upload',
    NewFormatDropdown = 'New Format Dropdown',
    Notification = 'Notification',
    PlanDetailModal = 'Plan Detail Modal',
    PluginCard = 'Plugin Card',
    Popover = 'Popover',
    PreviewAB = 'Preview AB',
    PricingCard = 'Pricing Card',
    PricingNav = 'Pricing Nav',
    ProfileCardAction = 'Profile Card Action',
    ProfileSurvey = 'Profile Survey',
    ProjectsAction = 'Projects Action',
    ReleaseSplitDetailModal = 'Release Split Detail Modal',
    RetentionOfferModal = 'Retention Offer Modal',
    RoomCard = 'Room Card',
    SampleTable = 'Sample Table',
    ServiceBrief = 'Service Brief',
    ServiceQuote = 'Service Quote',
    SessionsNavbarShortcut = 'Sessions Navbar Shortcut',
    SettingAction = 'Setting Action',
    SharedLink = 'Shared Link',
    StartVocAlignModal = 'Start VocAlign Modal',
    TextAction = 'Text Action',
    TrendingCharts = 'Trending Charts',
    TrialActivationModal = 'Trial Activation Modal',
    UpdateModal = 'Update Modal',
    UpsellBanner = 'Upsell Banner',
    UpsellButton = 'Upsell Button',
    UpsellCard = 'Upsell Card',
    UpsellModal = 'Upsell Modal',
    UpsellModalBasicDownload = 'Upsell Modal - Basic Download',
    UpsellModalFreeUserFreeDownload = 'Upsell Modal - Free User Free Download',
    UpsellModalFreeUserPaidDownload = 'Upsell Modal - Free User Paid Download',
    UpsellModalPaidUserPaidDownload = 'Upsell Modal - Paid User Paid Download',
    UseCreditModal = 'Use Credit Modal',
    WaitzineWhileMastering = 'Waitzine While Mastering',
    WaitzineWhilePBS = 'Waitzine While PBS',
    WaitzineWhilePreviewing = 'Waitzine While Previewing',
    WelcomeModal = 'Welcome Modal',
    WizardReviewStep = 'Wizard Review Step',
}

export enum MasterEqHighs {
    Dull = 'Dull',
    Good = 'Good',
    Harsh = 'Harsh',
}

export enum MasterEqIntensity {
    Good = 'Good',
    TooHigh = 'Too High',
    TooLow = 'Too Low',
}

export enum MasterEqLows {
    Boomy = 'Boomy',
    Good = 'Good',
    Thin = 'Thin',
}

export enum MasterEqMids {
    Good = 'Good',
    Muddy = 'Muddy',
    Weak = 'Weak',
}

export enum MasterEqVocals {
    Good = 'Good',
    Muted = 'Muted',
    Nasal = 'Nasal',
}

export enum MasterIntensity {
    High = 'High',
    Low = 'Low',
    Medium = 'Medium',
}

export enum MasterLoudness {
    Good = 'Good',
    TooLoud = 'Too Loud',
    TooQuiet = 'Too Quiet',
}

export enum MasterProductionStyle {
    Balanced = 'Balanced',
    Open = 'Open',
    Warm = 'Warm',
}

export enum MasterSibilanceApplication {
    Full = 'Full',
    HighVoices = 'High Voices',
    LowVoices = 'Low Voices',
}

export enum MasterSibilanceControl {
    Heavy = 'Heavy',
    Light = 'Light',
    None = 'None',
}

export enum ModalName {
    AddToProject = 'Add To Project',
    AlbumMaster = 'Album Master',
    CancelConfirmation = 'Cancel Confirmation',
    CancelFeatureLoss = 'Cancel Feature Loss',
    CancelOptions = 'Cancel Options',
    CancelReason = 'Cancel Reason',
    CheckoutWarningMobilePlan = 'Checkout Warning Mobile Plan',
    CompareModal = 'Compare Modal',
    ConfirmationPopover = 'Confirmation Popover',
    CopyLibraryLink = 'Copy Library Link',
    CopyProjectsLink = 'Copy Projects Link',
    FailedUpload = 'Failed Upload',
    InvitetoProject = 'Invite to Project',
    MasterFormat = 'Master Format',
    MasterPreview = 'Master Preview',
    MasterRevision = 'Master Revision',
    MasterRevisionReview = 'Master Revision Review',
    MasterWaitzine = 'Master Waitzine',
    MixFeedback = 'Mix Feedback',
    ProductDemo = 'Product Demo',
    RedeemLicence = 'Redeem Licence',
    ReferenceMaster = 'Reference Master',
    ReleaseImport = 'Release Import',
    ReleaseSplitAccountMismatch = 'Release Split Account Mismatch',
    ReleaseSplitCreation = 'Release Split Creation',
    ReleaseSplitDetail = 'Release Split Detail',
    RetentionOffer = 'Retention Offer',
    ShareAsset = 'Share Asset',
    ShareLibraryLink = 'Share Library Link',
    StartSelling = 'Start Selling',
    StartVocAlign = 'Start VocAlign',
    Sweetener = 'Sweetener',
    TrialActivation = 'Trial Activation',
    TrialSelection = 'Trial Selection',
    UpdatePaymentInfo = 'Update Payment Info',
    Upsell = 'Upsell',
    UpsellModalBasicDownload = 'Upsell Modal - Basic Download',
    UpsellModalFreeUserFreeDownload = 'Upsell Modal - Free User Free Download',
    UpsellModalFreeUserPaidDownload = 'Upsell Modal - Free User Paid Download',
    UpsellModalPaidUserPaidDownload = 'Upsell Modal - Paid User Paid Download',
    UpsellStems = 'Upsell Stems',
    UseCredits = 'Use Credits',
    WaitzineWhileMastering = 'Waitzine While Mastering',
    WaitzineWhilePBS = 'Waitzine While PBS',
    WaitzineWhilePreviewing = 'Waitzine While Previewing',
    WithdrawMoney = 'Withdraw Money',
    WizardReviewUpsell = 'Wizard Review Upsell',
}

export enum Relation {
    Collaborator = 'Collaborator',
    Independent = 'Independent',
    Owner = 'Owner',
}

export enum ShareMethod {
    CopyLink = 'Copy Link',
    Email = 'Email',
    ExternalPlatform = 'External Platform',
}

export type AssetCreatedProperties = {
    /**
     * album_created= boolean indicating if album creation was the reason for Asset Created to send
     */
    albumCreated: boolean;
    /**
     * asset category = category of asset i.e Mastered track, original track, album, artwork, sample, collection, chromatic set, stems, rehance etc.VALUES:Upon Upload: Original Track, Original Album ;Upon Mastering: Mastered Track, Mastered Album ;Upon Re-Mastering: Remastered Track, Remastered Album ;Upon Master Revision: Revised Track, Revised Album
     */
    assetCategory: AssetCategory;
    /**
     * asset_category_group= high level grouping of asset_category / asset_format. (i.e. Audio, Image, Video, Plugin)
     */
    assetCategoryGroup: AssetCategoryGroup;
    /**
     * asset_creation_scenario= reason for event trigger or nature of the action taken to cause asset creation (e.g.: mastering/remastering/revising, album creation, sample colleciton creation, etc.)
     */
    assetCreationScenario: AssetCreationScenario;
    /**
     * asset id = unique id of asset
     */
    assetId: string;
    /**
     * master_created= boolean indicating that asset mastered, remastered, or revised on this update. See master_type for nature of master.
     */
    masterCreated: boolean;
    /**
     * number files in asset = number of files nested in the asset
     */
    numberFilesInAsset: number;
    /**
     * reference_asset_applied= boolean indicating if reference mastering was applied
     */
    referenceAssetApplied: boolean;
    /**
     * sample_collection_created= boolean indicating if a sample collection creation was the reason for Asset Created to send
     */
    sampleCollectionCreated: boolean;
    /**
     * asset format = file format (after edit is made for Asset Edited, or at time of Upload with Asset Uploaded). E.g.: jpeg, wav, mp3
     */
    assetFormat?: string;
    /**
     * asset genre = genre of the asset (auto captured in Samples)
     */
    assetGenre?: string;
    /**
     * asset instrument = the main instrument of the asset. This is known for Samples and Chromatic, but may apply elsewhere. In the case of Stems, it represents the separated tracks that were downloaded (vocals, guitar, drums, other).
     */
    assetInstrument?: string;
    /**
     * asset length = length of asset (seconds to some decimal) if applicable
     */
    assetLength?: number;
    /**
     * asset_master_type = if this file is a mastered, or revised mastered file. This does not refer to any action taken at the time of the event, it simply describes the asset. Note we cannot distinguish a master from a remaster with this property. To see that, look at the CTA Clicked event.
     */
    assetMasterType?: AssetMasterType;
    /**
     * asset name = name of asset, whether given by user or automatically applied
     */
    assetName?: string;
    /**
     * asset size = size of file(s) in MB
     */
    assetSize?: number;
    /**
     * master_bit_depth = the bit depth of the mastered asset.
     */
    masterBitDepth?: string;
    /**
     * master distortion filter = boolean indicating if the distortion filter is applied or not on a revised mastered track
     */
    masterDistortion?: boolean;
    /**
     * master engine version= mastering engine version used to create the mastered asset, if applicable
     */
    masterEngineVersion?: string;
    /**
     * master eq highs = for a revised mastered track the eq highs (Ex. Harsh, Dull)
     */
    masterEqHighs?: MasterEqHighs;
    /**
     * master eq intensity = for a revised mastered track the intensity (Ex. Too Low, Good)
     */
    masterEqIntensity?: MasterEqIntensity;
    /**
     * master eq lows = for a revised mastered track the eq lows (Ex. Boomy, Thin)
     */
    masterEqLows?: MasterEqLows;
    /**
     * master eq mids = for a revised mastered track the eq  mids (Ex. Muddy, Weak)
     */
    masterEqMids?: MasterEqMids;
    /**
     * master eq vocals = for a revised mastered track the eq vocals (Ex. Nasal, Muted)
     */
    masterEqVocals?: MasterEqVocals;
    /**
     * master intensity = mastering intensity
     */
    masterIntensity?: MasterIntensity;
    /**
     * master loudness = for a revised mastered track the loudness (Ex. Good, Too Quiet, Too Loud)
     */
    masterLoudness?: MasterLoudness;
    /**
     * production style = mastering production style
     */
    masterProductionStyle?: MasterProductionStyle;
    /**
     * master_sample_rate = the sample rate of the mastered asset.
     */
    masterSampleRate?: string;
    /**
     * master sibilance application = for a revised mastered track where additional sibilance should be applied (ex. high voices, low voices, full)
     */
    masterSibilanceApplication?: MasterSibilanceApplication;
    /**
     * master sibilance control = for a revised mastered track the additional sibilance requested (light, heavy)
     */
    masterSibilanceControl?: MasterSibilanceControl;
    /**
     * master_stereofield_center_focus = boolean indicating if the center focus filter is applied or not on a revised mastered track
     */
    masterStereofieldCenterFocus?: boolean;
    /**
     * master_stereofield_width_expand = boolean indicating if the width expand filter is applied or not on a revised mastered track
     */
    masterStereofieldWidthExpand?: boolean;
    /**
     * original format = original format of the asset upon upload / before any alterations (e.g.: before the mastered asset was created)
     */
    originalFormat?: string;
    /**
     * original id = id of original asset upon upload / before any alterations (e.g.: before the mastered asset was created)
     */
    originalId?: string;
    /**
     * original size = original size of asset upon upload / before any alterations (e.g.: before the mastered asset was created)
     */
    originalSize?: number;
    /**
     * previous_bit_depth = the bit depth of the asset before mastering/remastering/revision. This is not the bit depth when the asset is uploaded.
     */
    previousBitDepth?: string;
    /**
     * previous_bit_depth = the sample rate of the asset before mastering/remastering/revision. This is not the sample rate when the asset is uploaded.
     */
    previousSampleRate?: string;
    /**
     * reference asset id = unique id of referenced asset (e.g.: in Mastering)
     */
    referenceAssetId?: string;
};

export type AssetPlayedProperties = {
    /**
     * asset category = category of asset i.e Mastered track, original track, album, artwork, sample, collection, chromatic set, stems, rehance etc.VALUES:Upon Upload: Original Track, Original Album ;Upon Mastering: Mastered Track, Mastered Album ;Upon Re-Mastering: Remastered Track, Remastered Album ;Upon Master Revision: Revised Track, Revised Album
     */
    assetCategory: AssetCategory;
    /**
     * asset id = unique id of asset
     */
    assetId: string;
    /**
     * asset_category_group= high level grouping of asset_category / asset_format. (i.e. Audio, Image, Video, Plugin)
     */
    assetCategoryGroup?: AssetCategoryGroup;
    /**
     * asset length = length of asset (seconds to some decimal) if applicable
     */
    assetLength?: number;
    /**
     * asset_master_type = if this file is a mastered, or revised mastered file. This does not refer to any action taken at the time of the event, it simply describes the asset. Note we cannot distinguish a master from a remaster with this property. To see that, look at the CTA Clicked event.
     */
    assetMasterType?: AssetMasterType;
    /**
     * asset name = name of asset, whether given by user or automatically applied
     */
    assetName?: string;
    /**
     * Name of the carousel (e.g. Daily New Releases) the Sample pack belongs to. This was created in order to not crowd the interaction_source event property.
     */
    ctaContentGroup?: string;
    /**
     * Name of the tab (e.g. Pop, House, Lofi) the sample / sample pack belongs to, at the given time. This was created in order to not crowd the interaction_source event property.
     */
    ctaContentList?: string;
    /**
     * interaction source = interaction the user took to perform the action (e.g.: name of notification, cta prompt, etc.)
     */
    interactionSource?: InteractionSource;
    /**
     * sample partner = provider of the sample, which differs from the asset_label
     */
    samplePartner?: string;
};

export type AssetSharedProperties = {
    /**
     * asset category = category of asset i.e Mastered track, original track, album, artwork, sample, collection, chromatic set, stems, rehance etc.VALUES:Upon Upload: Original Track, Original Album ;Upon Mastering: Mastered Track, Mastered Album ;Upon Re-Mastering: Remastered Track, Remastered Album ;Upon Master Revision: Revised Track, Revised Album
     */
    assetCategory: AssetCategory;
    /**
     * asset_category_group= high level grouping of asset_category / asset_format. (i.e. Audio, Image, Video, Plugin)
     */
    assetCategoryGroup: AssetCategoryGroup;
    /**
     * asset id = unique id of asset
     */
    assetId: string;
    /**
     * asset name = name of asset, whether given by user or automatically applied
     */
    assetName: string;
    /**
     * number files in asset = number of files nested in the asset
     */
    numberFilesInAsset: number;
    /**
     * relation = relation the user has to the owner of the asset (i.e. colaborator vs. owner, or independent in the case of Samples)
     */
    relation: Relation;
    /**
     * share_method = method the user selected to share the asset (email. copy link, external platform - FB, Twitter, Tiktok)
     */
    shareMethod: ShareMethod;
    /**
     * asset bpm = beats per minute of the asset. This is known for Samples, but may apply elsewhere
     */
    assetBpm?: number;
    /**
     * asset format = file format (after edit is made for Asset Edited, or at time of Upload with Asset Uploaded). E.g.: jpeg, wav, mp3
     */
    assetFormat?: string;
    /**
     * asset genre = genre of the asset (auto captured in Samples)
     */
    assetGenre?: string;
    /**
     * asset instrument = the main instrument of the asset. This is known for Samples and Chromatic, but may apply elsewhere. In the case of Stems, it represents the separated tracks that were downloaded (vocals, guitar, drums, other).
     */
    assetInstrument?: string;
    /**
     * asset key = the key of the asset. This is known for Samples, but may apply elsewhere
     */
    assetKey?: string;
    /**
     * asset length = length of asset (seconds to some decimal) if applicable
     */
    assetLength?: number;
    /**
     * asset_master_type = if this file is a mastered, or revised mastered file. This does not refer to any action taken at the time of the event, it simply describes the asset. Note we cannot distinguish a master from a remaster with this property. To see that, look at the CTA Clicked event.
     */
    assetMasterType?: AssetMasterType;
    /**
     * asset size = size of file(s) in MB
     */
    assetSize?: number;
    /**
     * interaction source = interaction the user took to perform the action (e.g.: name of notification, cta prompt, etc.)
     */
    interactionSource?: InteractionSource;
    /**
     * master distortion filter = boolean indicating if the distortion filter is applied or not on a revised mastered track
     */
    masterDistortion?: boolean;
    /**
     * master engine version= mastering engine version used to create the mastered asset, if applicable
     */
    masterEngineVersion?: string;
    /**
     * master eq highs = for a revised mastered track the eq highs (Ex. Harsh, Dull)
     */
    masterEqHighs?: MasterEqHighs;
    /**
     * master eq intensity = for a revised mastered track the intensity (Ex. Too Low, Good)
     */
    masterEqIntensity?: MasterEqIntensity;
    /**
     * master eq lows = for a revised mastered track the eq lows (Ex. Boomy, Thin)
     */
    masterEqLows?: MasterEqLows;
    /**
     * master eq mids = for a revised mastered track the eq  mids (Ex. Muddy, Weak)
     */
    masterEqMids?: MasterEqMids;
    /**
     * master eq vocals = for a revised mastered track the eq vocals (Ex. Nasal, Muted)
     */
    masterEqVocals?: MasterEqVocals;
    /**
     * master intensity = mastering intensity
     */
    masterIntensity?: MasterIntensity;
    /**
     * master loudness = for a revised mastered track the loudness (Ex. Good, Too Quiet, Too Loud)
     */
    masterLoudness?: MasterLoudness;
    /**
     * production style = mastering production style
     */
    masterProductionStyle?: MasterProductionStyle;
    /**
     * master sibilance application = for a revised mastered track where additional sibilance should be applied (ex. high voices, low voices, full)
     */
    masterSibilanceApplication?: MasterSibilanceApplication;
    /**
     * master sibilance control = for a revised mastered track the additional sibilance requested (light, heavy)
     */
    masterSibilanceControl?: MasterSibilanceControl;
    /**
     * release genre = user entered genre of release
     */
    releaseGenre?: string;
    /**
     * release id = id of release
     */
    releaseId?: string;
    /**
     * share_recipients = string of recipients, separated by comma in a list if there are multiple. (user emails, external account prodiver name)
     */
    shareRecipients?: string;
};

export type CreatorUpdatedProperties = {
    /**
     * creator_bpm = creator bpm controlled by user
     */
    creatorBpm: number;
    /**
     * creator_key = key of the track controlled by the user
     */
    creatorKey: string;
    /**
     * creator_session_id = session id for the creator modal
     */
    creatorSessionId: string;
    /**
     * deleted_creator_session = boolean indicating if a user cleared their creator session
     */
    deletedCreatorSession: boolean;
    /**
     * samples_in_creator = number of samples in the creator AFTER this update
     */
    numberSamplesInCreator: number;
    /**
     * sample_added = boolean indicating if this sample was just added to the creator on THIS UPDATE
     */
    sampleAdded: boolean;
    /**
     * sample_removed = boolean indicating if an individual sample was removed from the creator on THIS UPDATE. This does not apply when the entire creator session is cleared; it only applies when an individual sample is removed.
     */
    sampleRemoved: boolean;
    /**
     * asset artist = artist. This is known for Samples, but may apply elsewhere
     */
    assetArtist?: string;
    /**
     * asset bpm = beats per minute of the asset. This is known for Samples, but may apply elsewhere
     */
    assetBpm?: number;
    /**
     * asset genre = genre of the asset (auto captured in Samples)
     */
    assetGenre?: string;
    /**
     * asset id = unique id of asset
     */
    assetId?: string;
    /**
     * asset instrument = the main instrument of the asset. This is known for Samples and Chromatic, but may apply elsewhere. In the case of Stems, it represents the separated tracks that were downloaded (vocals, guitar, drums, other).
     */
    assetInstrument?: string;
    /**
     * asset key = the key of the asset. This is known for Samples, but may apply elsewhere
     */
    assetKey?: string;
    /**
     * asset label = label the asset belongs to. This is known for Samples, but may apply elsewhere
     */
    assetLabel?: string;
    /**
     * asset length = length of asset (seconds to some decimal) if applicable
     */
    assetLength?: number;
    /**
     * asset level = the volume of the asset with respect to others in the creator. This is known for Samples in Creator, but may apply elsewhere
     */
    assetLevel?: string;
    /**
     * asset loop type = Ex. loop vs. one shot. This is known for Samples, but may apply elsewhere
     */
    assetLoopType?: string;
    /**
     * asset name = name of asset, whether given by user or automatically applied
     */
    assetName?: string;
    /**
     * asset pitch = pitch of asset played. This is known for Samples, but may apply elsewhere
     */
    assetPitch?: string;
    /**
     * asset sub genre = additional categorical information Ex. Acoustic, attack, chill, dreamy etc. This is known for Samples, but may apply elsewhere
     */
    assetSubGenre?: string;
    /**
     * asset tempo = tempo of asset. This is known for Samples, but may apply elsewhere
     */
    assetTempo?: string;
    /**
     * sample pack id = if the asset belongs to a pack, the id of that pack
     */
    samplePackId?: string;
    /**
     * sample pack name = if the asset belongs to a pack, the name of that pack
     */
    samplePackName?: string;
    /**
     * sample partner = provider of the sample, which differs from the asset_label
     */
    samplePartner?: string;
};

export type CTAClickedProperties = {
    /**
     * cta goal = the purpose of the CTA (i.e. master track, sample download, album creation/initialization)
     */
    ctaGoal: CtaGoal;
    /**
     * cta type = the type of CTA {e.g.: button, interstitial, banner}
     */
    ctaType: CtaType;
    /**
     * interaction source = interaction the user took to perform the action (e.g.: name of notification, cta prompt, etc.)
     */
    interactionSource: InteractionSource;
    /**
     * Name of the carousel (e.g. Daily New Releases) the Sample pack belongs to. This was created in order to not crowd the interaction_source event property.
     */
    ctaContentGroup?: string;
    /**
     * Name of the tab (e.g. Pop, House, Lofi) the sample / sample pack belongs to, at the given time. This was created in order to not crowd the interaction_source event property.
     */
    ctaContentList?: string;
    /**
     * cta destination = generally a URL destination of clicking the CTA. Before the 2021 schema update, this is called CTA target (which is confusing with CTA goal).
     */
    ctaDestination?: string;
    /**
     * cta text = the literal text displayed of the CTA (if applicable). Pass this text in English, regardless of the language that the user sees.
     */
    ctaText?: string;
};

export type ModalOpenedProperties = {
    /**
     * modal name = name of modal
     */
    modalName: ModalName;
    /**
     * The unique convert experiment variant associated with the event
     */
    experimentVariant?: string;
    /**
     * interaction source = interaction the user took to perform the action (e.g.: name of notification, cta prompt, etc.)
     */
    interactionSource?: InteractionSource;
};

export class AnalyticsV2Service extends AbstractSegmentServiceV2 {
    constructor(platformNameOverride?: AnalyticsPlatformName, windowObject?: SegmentWindow) {
        super(
            AnalyticsApplication.Samples,
            windowObject || (typeof window === 'undefined' ? undefined : window),
            platformNameOverride,
        );
    }
    /**
     * Triggered when a user creates a new asset or file type. This include when a track is mastered, an album is created (by grouping files from the library or uploading them), or when a Sample Collection is created. This does not include when assets are simply uploaded (that is Asset Uploaded).If an album is mastered, the event fires only once where number_assets_in_file indicates how many tracks are in album.In Samples, this fires only when the collection is first created; not as the user edits the Collection (that would trigger the Asset Edited event instead.
     */
    trackAssetCreated(
        properties: AssetCreatedProperties,
    ): Promise<void> {
        return this.track(
            'Asset Created',
            {
                album_created: properties.albumCreated,
                asset_category: properties.assetCategory,
                asset_category_group: properties.assetCategoryGroup,
                asset_creation_scenario: properties.assetCreationScenario,
                asset_id: properties.assetId,
                master_created: properties.masterCreated,
                number_files_in_asset: properties.numberFilesInAsset,
                reference_asset_applied: properties.referenceAssetApplied,
                sample_collection_created: properties.sampleCollectionCreated,
                asset_format: properties.assetFormat ?? null,
                asset_genre: properties.assetGenre ?? null,
                asset_instrument: properties.assetInstrument ?? null,
                asset_length: properties.assetLength ?? null,
                asset_master_type: properties.assetMasterType ?? null,
                asset_name: properties.assetName ?? null,
                asset_size: properties.assetSize ?? null,
                master_bit_depth: properties.masterBitDepth ?? null,
                master_distortion: properties.masterDistortion ?? null,
                master_engine_version: properties.masterEngineVersion ?? null,
                master_eq_highs: properties.masterEqHighs ?? null,
                master_eq_intensity: properties.masterEqIntensity ?? null,
                master_eq_lows: properties.masterEqLows ?? null,
                master_eq_mids: properties.masterEqMids ?? null,
                master_eq_vocals: properties.masterEqVocals ?? null,
                master_intensity: properties.masterIntensity ?? null,
                master_loudness: properties.masterLoudness ?? null,
                master_production_style: properties.masterProductionStyle ?? null,
                master_sample_rate: properties.masterSampleRate ?? null,
                master_sibilance_application: properties.masterSibilanceApplication ?? null,
                master_sibilance_control: properties.masterSibilanceControl ?? null,
                master_stereofield_center_focus: properties.masterStereofieldCenterFocus ?? null,
                master_stereofield_width_expand: properties.masterStereofieldWidthExpand ?? null,
                original_format: properties.originalFormat ?? null,
                original_id: properties.originalId ?? null,
                original_size: properties.originalSize ?? null,
                previous_bit_depth: properties.previousBitDepth ?? null,
                previous_sample_rate: properties.previousSampleRate ?? null,
                reference_asset_id: properties.referenceAssetId ?? null,
            },
        );
    }
    /**
     * Triggered in specific scenarios when an asset is played, but does not apply to every instance on the website where music or video is played. In Chromatic this is fired only when the on boarding video is played (not when a kit is played). In Samples, this is fired for Sample Packs and Repacks only. This is not triggered in Mastering / Library. See notion page for other examples.
     */
    trackAssetPlayed(
        properties: AssetPlayedProperties,
    ): Promise<void> {
        return this.track(
            'Asset Played',
            {
                asset_category: properties.assetCategory,
                asset_id: properties.assetId,
                asset_category_group: properties.assetCategoryGroup ?? null,
                asset_length: properties.assetLength ?? null,
                asset_master_type: properties.assetMasterType ?? null,
                asset_name: properties.assetName ?? null,
                cta_content_group: properties.ctaContentGroup ?? null,
                cta_content_list: properties.ctaContentList ?? null,
                interaction_source: properties.interactionSource ?? null,
                sample_partner: properties.samplePartner ?? null,
            },
        );
    }
    /**
     * Triggered when an asset is shared, whether to an individual via email, to social media, or by copying a link.
     */
    trackAssetShared(
        properties: AssetSharedProperties,
    ): Promise<void> {
        return this.track(
            'Asset Shared',
            {
                asset_category: properties.assetCategory,
                asset_category_group: properties.assetCategoryGroup,
                asset_id: properties.assetId,
                asset_name: properties.assetName,
                number_files_in_asset: properties.numberFilesInAsset,
                relation: properties.relation,
                share_method: properties.shareMethod,
                asset_bpm: properties.assetBpm ?? null,
                asset_format: properties.assetFormat ?? null,
                asset_genre: properties.assetGenre ?? null,
                asset_instrument: properties.assetInstrument ?? null,
                asset_key: properties.assetKey ?? null,
                asset_length: properties.assetLength ?? null,
                asset_master_type: properties.assetMasterType ?? null,
                asset_size: properties.assetSize ?? null,
                interaction_source: properties.interactionSource ?? null,
                master_distortion: properties.masterDistortion ?? null,
                master_engine_version: properties.masterEngineVersion ?? null,
                master_eq_highs: properties.masterEqHighs ?? null,
                master_eq_intensity: properties.masterEqIntensity ?? null,
                master_eq_lows: properties.masterEqLows ?? null,
                master_eq_mids: properties.masterEqMids ?? null,
                master_eq_vocals: properties.masterEqVocals ?? null,
                master_intensity: properties.masterIntensity ?? null,
                master_loudness: properties.masterLoudness ?? null,
                master_production_style: properties.masterProductionStyle ?? null,
                master_sibilance_application: properties.masterSibilanceApplication ?? null,
                master_sibilance_control: properties.masterSibilanceControl ?? null,
                release_genre: properties.releaseGenre ?? null,
                release_id: properties.releaseId ?? null,
                share_recipients: properties.shareRecipients ?? null,
            },
        );
    }
    /**
     * Triggered when a Sample is added to the Creator, removed from the Creator, or when the Creator is cleared entirely. Note that this event does not fire for EVERY action in Creator. Other events like Asset Shared, Asset Downloaded, Asset Favourite Updated, etc. fire.
     */
    trackCreatorUpdated(
        properties: CreatorUpdatedProperties,
    ): Promise<void> {
        return this.track(
            'Creator Updated',
            {
                creator_bpm: properties.creatorBpm,
                creator_key: properties.creatorKey,
                creator_session_id: properties.creatorSessionId,
                deleted_creator_session: properties.deletedCreatorSession,
                number_samples_in_creator: properties.numberSamplesInCreator,
                sample_added: properties.sampleAdded,
                sample_removed: properties.sampleRemoved,
                asset_artist: properties.assetArtist ?? null,
                asset_bpm: properties.assetBpm ?? null,
                asset_genre: properties.assetGenre ?? null,
                asset_id: properties.assetId ?? null,
                asset_instrument: properties.assetInstrument ?? null,
                asset_key: properties.assetKey ?? null,
                asset_label: properties.assetLabel ?? null,
                asset_length: properties.assetLength ?? null,
                asset_level: properties.assetLevel ?? null,
                asset_loop_type: properties.assetLoopType ?? null,
                asset_name: properties.assetName ?? null,
                asset_pitch: properties.assetPitch ?? null,
                asset_sub_genre: properties.assetSubGenre ?? null,
                asset_tempo: properties.assetTempo ?? null,
                sample_pack_id: properties.samplePackId ?? null,
                sample_pack_name: properties.samplePackName ?? null,
                sample_partner: properties.samplePartner ?? null,
            },
        );
    }
    /**
     * Triggered when a user clicks a CTA button on a page. See Notion page for all details of when CTA Clicked is triggered on the website: https://www.notion.so/landr/CTA-Clicked-cc35afb358ca4765ae7c55b19d8a2715
     */
    trackCTAClicked(
        properties: CTAClickedProperties,
    ): Promise<void> {
        return this.track(
            'CTA Clicked',
            {
                cta_goal: properties.ctaGoal,
                cta_type: properties.ctaType,
                interaction_source: properties.interactionSource,
                cta_content_group: properties.ctaContentGroup ?? null,
                cta_content_list: properties.ctaContentList ?? null,
                cta_destination: properties.ctaDestination ?? null,
                cta_text: properties.ctaText ?? null,
            },
        );
    }
    /**
     * Triggered when a modal is opened, whether by the user or automatically (i.e. pop up).
     */
    trackModalOpened(
        properties: ModalOpenedProperties,
    ): Promise<void> {
        return this.track(
            'Modal Opened',
            {
                modal_name: properties.modalName,
                experiment_variant: properties.experimentVariant ?? null,
                interaction_source: properties.interactionSource ?? null,
            },
        );
    }
}
