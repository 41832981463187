import { PlanCodes } from 'constants/api/planCodes';
import { mockBuilder } from 'mocks/mockBuilder';
import {
    aCurrentSubscriptionDetails,
    aSubscriptionDetails,
    aSubscriptionsAndAllowedFeatures,
} from 'mocks/generated/graphql';
import { SubscriptionDetailsStatus } from 'types/generated/graphql';
import { MockedResponse } from '@apollo/client/testing';
import { ActivateTrialSubscription } from './user.gql';

export const anActivateTrialSubscriptionMutationMock =
    mockBuilder<MockedResponse>({
        request: {
            query: ActivateTrialSubscription,
        },
        result: {
            data: {
                activateTrialSubscription: aSubscriptionsAndAllowedFeatures({
                    subscriptionsDetails: [
                        aSubscriptionDetails({
                            status: SubscriptionDetailsStatus.Trial,
                            isTrial: false,
                            isTrialingSubscription: true,
                            current: aCurrentSubscriptionDetails({
                                code: PlanCodes['all_access'],
                            }),
                        }),
                    ],
                }),
            },
        },
        error: undefined,
    });

export const anActivateTrialSubscriotionMutationErrorMock =
    mockBuilder<MockedResponse>({
        request: {
            query: ActivateTrialSubscription,
        },
        result: undefined,
        error: Error('Error activating trial subscription'),
    });
