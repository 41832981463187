import React, { useContext, useCallback, PropsWithChildren } from 'react';
import {
    AccountStatusEnum,
    SubscriptionsAndAllowedFeaturesQuery,
} from 'types/generated/graphql';
import {
    SubscriptionsAndAllowedFeatures,
    useUserAccountStatusQuery,
} from 'apollo/user';
import { neverop } from 'utils';
import { useQueryWhenAuthenticated } from 'hooks';

type RefetchIfNeededParams = {
    isPoorStanding: boolean;
    isPlanPaused: boolean;
};

export type SubscriptionsAndAllowedFeaturesContextType = {
    hasFetched: boolean;
    hasSamplesPlan: boolean;
    hasStudioPlan: boolean;
    hasSubscription: boolean;
    upsellingAllowed: boolean;
    isPlanPaused: boolean;
    isPoorStanding: boolean;
    isTrialSubscription: boolean;
    unallowedByPlan?: string;
    subscriptionsDetails:
        | NonNullable<
              SubscriptionsAndAllowedFeaturesQuery['subscriptionsAndAllowedFeatures']
          >['subscriptionsDetails']
        | undefined;
    refetchIfNeeded: (props: RefetchIfNeededParams) => void;
};

export const defaultSubscriptionsAndAllowedFeaturesContext = {
    hasFetched: false,
    hasSamplesPlan: false,
    hasStudioPlan: false,
    hasSubscription: false,
    upsellingAllowed: false,
    isPlanPaused: false,
    isPoorStanding: false,
    isTrialSubscription: false,
    unallowedByPlan: undefined,
    subscriptionsDetails: undefined,
    refetchIfNeeded: neverop(
        'SubscriptionsAndAllowedFeaturesContext',
        'refetch',
    ),
};

export const SubscriptionsAndAllowedFeaturesContext =
    React.createContext<SubscriptionsAndAllowedFeaturesContextType>(
        defaultSubscriptionsAndAllowedFeaturesContext,
    );

// Exported for testing
export const useSubscriptionsAndAllowedFeaturesProvider = () => {
    const {
        data,
        loading: isSubscriptionAndAllowedFeaturesLoading,
        doQuery: refetchSubscriptionAndAllowedFeatures,
    } = useQueryWhenAuthenticated<SubscriptionsAndAllowedFeaturesQuery>({
        query: SubscriptionsAndAllowedFeatures,
    });

    // TODO: Remove this hook when allowed feature will support unallowedReason.reason = `PoorStanding`
    const {
        accountBalanceSummary,
        refetch: refetchUserAccountStatus,
        isLoading: accountBalanceSummaryLoading,
    } = useUserAccountStatusQuery();

    // This will never resolve to true when anonymous user
    const hasFetched = !!(
        !isSubscriptionAndAllowedFeaturesLoading &&
        data?.subscriptionsAndAllowedFeatures &&
        !accountBalanceSummaryLoading &&
        accountBalanceSummary
    );

    const hasSamplesPlan =
        !!data?.subscriptionsAndAllowedFeatures?.hasSamplesPlan;
    const hasStudioPlan =
        !!data?.subscriptionsAndAllowedFeatures?.hasStudioPlan;
    const hasSubscription =
        !!data?.subscriptionsAndAllowedFeatures?.hasSubscription;
    const upsellingAllowed =
        !!data?.subscriptionsAndAllowedFeatures?.upsellingAllowed;
    const samplePaidContentAccess =
        data?.subscriptionsAndAllowedFeatures?.samplePaidContentAccess;

    const isPlanPaused =
        samplePaidContentAccess?.unallowedReason?.reason ===
        'PausedSubscription';

    const isPoorStanding =
        accountBalanceSummary?.accountingStatus ===
            AccountStatusEnum.PoorStanding ||
        accountBalanceSummary?.accountingStatus ===
            AccountStatusEnum.Collection;

    const isTrialSubscription =
        samplePaidContentAccess?.unallowedReason?.reason ===
        'TrialSubscription';

    const unallowedByPlan =
        samplePaidContentAccess?.unallowedReason?.unallowedByPlan;

    const refetchIfNeeded = useCallback(
        (props: RefetchIfNeededParams) => {
            if (props.isPoorStanding !== isPoorStanding) {
                refetchUserAccountStatus();
            }

            if (props.isPlanPaused !== isPlanPaused) {
                refetchSubscriptionAndAllowedFeatures();
            }
        },
        [
            refetchSubscriptionAndAllowedFeatures,
            refetchUserAccountStatus,
            isPoorStanding,
            isPlanPaused,
        ],
    );

    const subscriptionsDetails =
        data?.subscriptionsAndAllowedFeatures?.subscriptionsDetails;

    return {
        hasFetched,
        hasSamplesPlan,
        hasStudioPlan,
        hasSubscription,
        upsellingAllowed,
        isPlanPaused,
        isPoorStanding,
        isTrialSubscription,
        unallowedByPlan,
        subscriptionsDetails,
        refetchIfNeeded,
    };
};

export const SubscriptionsAndAllowedFeaturesProvider: React.FC<
    PropsWithChildren
> = ({ children }) => {
    const context = useSubscriptionsAndAllowedFeaturesProvider();

    return (
        <SubscriptionsAndAllowedFeaturesContext.Provider value={context}>
            {children}
        </SubscriptionsAndAllowedFeaturesContext.Provider>
    );
};

export const useSubscriptionsAndAllowedFeaturesContext =
    (): SubscriptionsAndAllowedFeaturesContextType =>
        useContext(SubscriptionsAndAllowedFeaturesContext);

export default SubscriptionsAndAllowedFeaturesProvider;
