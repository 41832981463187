import { LazyQueryHookOptions, useMutation } from '@apollo/client';
import {
    AcceptUserAgreementsMutation,
    AcceptUserAgreementsMutationVariables,
    Agreement,
    LegalAgreementKindEnum,
    User,
} from 'types/generated/graphql';
import {
    DoQuery,
    useQueryWhenAuthenticated,
} from 'hooks/useQueryWhenAuthenticated';
import {
    AcceptUserAgreements,
    UserAgreements,
    UserCredits,
    UserProfile,
    AccountBalanceSummary,
    ActivateTrialSubscription,
} from './user.gql';

export type ApolloUserSamplesSubscription = {
    id: string;
    plan: {
        code: string;
    };
};

export const useUserProfile = (): {
    userProfile?: User;
    isUserLoading: boolean;
} => {
    const { data, loading } = useQueryWhenAuthenticated({
        query: UserProfile,
    });

    return { userProfile: data?.userProfile, isUserLoading: loading };
};

export const useUserCredits = (): {
    userCredits?: { remaining: number };
    doQuery: DoQuery;
} => {
    const { data, doQuery } = useQueryWhenAuthenticated({
        query: UserCredits,
    });

    return {
        userCredits: data?.userCredits,
        doQuery: doQuery as DoQuery,
    };
};

export const useUserAgreements = (
    options?: LazyQueryHookOptions,
): {
    userAgreements?: Agreement[];
    doQuery: DoQuery;
} => {
    const { data, doQuery } = useQueryWhenAuthenticated({
        query: UserAgreements,
        options,
    });

    return {
        userAgreements: data?.userAgreements?.agreements,
        doQuery: doQuery as DoQuery,
    };
};

// MUTATIONS

export const useAcceptUserAgreements = (
    kinds: LegalAgreementKindEnum[] = [],
) => {
    const [acceptAgreements] = useMutation<
        AcceptUserAgreementsMutation,
        AcceptUserAgreementsMutationVariables
    >(AcceptUserAgreements, {
        variables: { agreements: { kinds } },
        refetchQueries: [
            {
                query: UserAgreements,
            },
        ],
        awaitRefetchQueries: true,
    });

    return acceptAgreements;
};

export const useActivateTrialSubscription = () => {
    const [activateTrialSubscription, { loading, error, data }] = useMutation(
        ActivateTrialSubscription,
        {
            onError: () => {
                // Do nothing
            },
        },
    );

    return {
        activateTrialSubscription,
        loading,
        error,
        data,
    };
};

export const useUserAccountStatusQuery = () => {
    const { data, loading, doQuery } = useQueryWhenAuthenticated({
        query: AccountBalanceSummary,
    });

    return {
        accountBalanceSummary: data?.accountBalanceSummary,
        isLoading: loading,
        refetch: doQuery,
    };
};
