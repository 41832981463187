const AllowedCanonicalParams = ['page', 'tab'];

export const getCanonicalUrl = (currentUrl: string): string => {
    try {
        const url = new URL(currentUrl);
        const params = new URLSearchParams(url.search);
        const canonicalParams = new URLSearchParams();

        // Only keep allowed parameters
        AllowedCanonicalParams.forEach((param) => {
            const value = params.get(param);

            // Skip page=1 since it's redundant
            if (param === 'page' && value === '1') {
                return;
            }

            if (value) {
                canonicalParams.append(param, value);
            }
        });

        // Reconstruct URL with only allowed params
        const canonicalUrl = `${url.origin}${url.pathname}`;
        const search = canonicalParams.toString();

        return search ? `${canonicalUrl}?${search}` : canonicalUrl;
    } catch {
        // Return a proper fallback URL
        return `${window?.location?.origin || ''}${
            window?.location?.pathname || '/'
        }`;
    }
};
