import { isMfeMode } from 'constants/isMfeMode';
import { AuthAnalyticsProperties, AppState } from '@landr/auth';
import { Environment } from '@landr/core';
import { AbstractAuth, AbstractAuthUser } from './abstractAuth';

class AuthService extends AbstractAuth {
    private auth: AbstractAuth | null = null;
    private initPromise: Promise<void> | null = null;

    public waitForInitialization(): Promise<void> {
        if (this.auth) {
            return this.auth.waitForInitialization();
        }

        // If not initialized yet, wait for it
        if (!this.initPromise) {
            this.initPromise = new Promise((resolve) => {
                const checkAuth = () => {
                    if (this.auth) {
                        resolve(this.auth.waitForInitialization());
                    } else {
                        setTimeout(checkAuth, 50);
                    }
                };
                checkAuth();
            });
        }
        return this.initPromise;
    }

    public async initialize(options: {
        environment: Environment;
        offlineUrl: string;
    }): Promise<void> {
        if (isMfeMode) {
            await import('./mfeAuth').then(({ MfeAuth }) => {
                this.auth = new MfeAuth();
            });
        } else {
            await import('./legacyAuth').then(({ LegacyAuth }) => {
                this.auth = new LegacyAuth();
            });
        }
        return this.getAuth().initialize(options);
    }

    public login(options?: { forceLoginPrompt?: boolean }): Promise<void> {
        return this.getAuth().login(options);
    }
    public signup(): Promise<void> {
        return this.getAuth().signup();
    }
    public logout(): Promise<void> {
        return this.getAuth().logout();
    }
    public isAuthenticated(): Promise<boolean> {
        return this.getAuth().isAuthenticated();
    }
    public getAccessToken(): Promise<string | null> {
        return this.getAuth().getAccessToken();
    }
    public getIsSignUp(): boolean {
        return this.getAuth().getIsSignUp();
    }
    public continueOrRedirectToProfileSurvey(): void {
        return this.getAuth().continueOrRedirectToProfileSurvey();
    }
    public getUser(): Promise<AbstractAuthUser | null> {
        return this.getAuth().getUser();
    }
    public getAnalyticsProperties(): Promise<AuthAnalyticsProperties | null> {
        return this.getAuth().getAnalyticsProperties();
    }
    public getAppState(): AppState | null {
        return this.getAuth().getAppState();
    }

    private getAuth(): AbstractAuth {
        if (!this.auth) {
            throw new Error('Auth not initialized');
        }
        return this.auth;
    }
}

export const auth: AbstractAuth = new AuthService();
