import { auth } from './utils/auth';
import { sendAnalyticsIdentify } from './utils/analytics/sendAnalyticsIdentify';
import { EventIds } from './constants';
import { featureFlagService } from './helpers/featureFlagsService/feature-flag-service';
import { CONFIG, analytics, log } from './utils';

const environment = CONFIG.VITE_APP_ENV;

function loadAnalytics() {
    if (!window.prerenderCloudIsServerSideRendering) {
        analytics.load(CONFIG.VITE_SEGMENT_WRITE_KEY);
    }
}

function getOfflineUrl(): string {
    return `${CONFIG.VITE_OFFLINE_URL}?returnUrl=${encodeURIComponent(
        window.location.href,
    )}`;
}

// Exporting for test purposes
export async function bootstrap() {
    try {
        await auth.initialize({ environment, offlineUrl: getOfflineUrl() });

        await Promise.all([featureFlagService.load(), sendAnalyticsIdentify()]);
        loadAnalytics();

        auth.continueOrRedirectToProfileSurvey();
    } catch (error) {
        log.error(
            'Failed to bootstrap root-app',
            EventIds.BootstrapError,
            error,
        );
    }
}

bootstrap();
