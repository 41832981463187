// IMPORTANT: Updating imports here should be added to the `manualChunk.initialTheme`
// entry in `vite.config.ts`
import { isMfeMode } from 'constants/isMfeMode';
import React from 'react';
import { MaestroProvider, useEnhancedEffect } from '@landr/maestro';
import {
    color,
    defaultTheme,
    MaestroThemeProvider,
} from '@landr/maestro-legacy';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    body {
        color: ${color('text.base')};
        background: ${color('background.base')};
        margin: 0;
        width: 100%; 
        height: 100%;
        overflow: hidden;
        overscroll-behavior: none;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    #root {
        min-height: 100vh;
        max-height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }
` as any;

export const AppStyleProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    useEnhancedEffect(() => {
        if (isMfeMode) {
            document.documentElement.classList.remove('light');
            document.documentElement.classList.add('dark');
        }
    }, []);

    return (
        <MaestroProvider forceColorMode="dark">
            <MaestroThemeProvider theme={defaultTheme}>
                {!isMfeMode && <GlobalStyle />}
                {children}
            </MaestroThemeProvider>
        </MaestroProvider>
    );
};

export default AppStyleProvider;
