import React from 'react';
import { Tooltip, Box, InfoOutlined } from '@landr/maestro';
import { t } from '@lingui/macro';
import { css } from '@landr/styled-system/css';

const errorWrapper = css({
    position: 'relative',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 'base',
    border: '1px solid neutral.800',
});

const iconWrapper = css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
});

// Use `withErrorBoundary` with the variant `minimal` or use it directly in your component
// when you are handling an error and wish to render only a minimal ui.
// For more info consult error/README.md
const MinimalError = () => {
    return (
        <Box className={errorWrapper}>
            <Tooltip label={t`Failed to load`} position="top" hasArrow>
                <Box className={iconWrapper}>
                    <InfoOutlined size="md" color="neutral.800" />
                </Box>
            </Tooltip>
        </Box>
    );
};

export default MinimalError;
