import { css } from '@landr/styled-system/css';

export const portalStyles = css({
    padding: 'md',
    pointerEvents: 'none',
    width: '100%',
});

export const notificationStyles = css({
    cursor: 'pointer',
    pointerEvents: 'all',
    minWidth: '300px',
    '& span': {
        display: 'block',
        maxWidth: 'calc(100% - 84px)',
        overflow: 'hidden',
    },
});

export const messageWrapperStyles = css({
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    gap: '0.25em',
});
