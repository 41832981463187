import React, { useContext, useState, useCallback } from 'react';
import { neverop } from 'utils/noop';
import getOpacityFromForegroundColor from 'helpers/getOpacityFromForegroundColor';

type GradientOpacity = 'subtle' | 'overlay';

const defaultColor = '#2c3c4d';
const defaultOpacity: GradientOpacity = 'subtle';

type Gradients = {
    color: string;
    opacity: GradientOpacity;
};

const useGradientController = () => {
    const [gradients, setGradients] = useState<Gradients>({
        color: defaultColor,
        opacity: defaultOpacity,
    });

    const updateGradient = useCallback(
        (updateBackgroundColor?: string, updateForegroundColor?: string) => {
            const opacity = updateForegroundColor
                ? getOpacityFromForegroundColor(updateForegroundColor)
                : defaultOpacity;

            setGradients({
                color: updateBackgroundColor ?? defaultColor,
                opacity: opacity,
            });
        },
        [],
    );

    return { updateGradient, ...gradients };
};

export type GradientContextType = {
    updateGradient: (
        updateBackgroundColor?: string,
        updateForegroundColor?: string,
    ) => void;
    color: string;
    opacity: GradientOpacity;
};

export const defaultGradientContext = {
    updateGradient: neverop('GradientContext', 'updateGradient'),
    color: defaultColor,
    opacity: defaultOpacity,
};

export const GradientContext = React.createContext<GradientContextType>(
    defaultGradientContext,
);

export const useGradientContext = (): GradientContextType =>
    useContext(GradientContext);

export const GradientProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const { updateGradient, color, opacity } = useGradientController();

    return (
        <GradientContext.Provider value={{ updateGradient, color, opacity }}>
            {children}
        </GradientContext.Provider>
    );
};

export default GradientProvider;
