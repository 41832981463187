import { MaxNbOfNotifications } from 'constants/notifications';
import { useState, useCallback } from 'react';
import { NotificationsContextType } from './types';
import { NotificationBase } from './types';

const removeDuplicateNotification = (
    newNotification: NotificationBase,
    notifications: NotificationBase[],
) => {
    const index = notifications.findIndex((notification) => {
        const { id, ...restNotification } = notification;
        const { id: _, ...restNewNotification } = newNotification;

        return (
            JSON.stringify({
                ...restNotification,
                type: notification.constructor.name,
            }) ===
            JSON.stringify({
                ...restNewNotification,
                type: newNotification.constructor.name,
            })
        );
    });

    if (index > -1) {
        notifications.splice(index, 1);
    }
};

const limitNotificationsLength = (notifications: NotificationBase[]) => {
    if (notifications.length >= MaxNbOfNotifications) {
        notifications.pop();
    }
};

export const useNotificationsController = (): NotificationsContextType => {
    const [notifications, setNotifications] = useState<NotificationBase[]>([]);

    const removeNotification = useCallback((id: string) => {
        setNotifications((notifications) => {
            const index = notifications.findIndex(
                (notification) => notification.id === id,
            );

            if (index > -1) {
                notifications.splice(index, 1);
                return [...notifications];
            }
            return notifications;
        });
    }, []);

    const createNotification = useCallback(
        (newNotification: NotificationBase) => {
            setNotifications((notifications) => {
                removeDuplicateNotification(newNotification, notifications);
                limitNotificationsLength(notifications);

                return [newNotification, ...notifications];
            });
        },
        [],
    );

    return {
        notifications,
        createNotification,
        removeNotification,
    };
};
