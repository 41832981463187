import React, { useContext, useState, PropsWithChildren } from 'react';
import { noop } from 'utils/noop';
import { ErrorBoundaryVariant } from 'types';

export type ErrorBoundaryErrorType = {
    variant: ErrorBoundaryVariant;
    status?: number;
};

export type ErrorBoundaryContextType = {
    error?: ErrorBoundaryErrorType;
    setError: React.Dispatch<
        React.SetStateAction<ErrorBoundaryErrorType | undefined>
    >;
};

export const DefaultErrorBoundaryContext: ErrorBoundaryContextType = {
    error: undefined,
    setError: noop,
};

export const ErrorBoundaryContext = React.createContext(
    DefaultErrorBoundaryContext,
);

export const useErrorBoundaryContext = (): ErrorBoundaryContextType =>
    useContext(ErrorBoundaryContext);

export const ErrorBoundaryProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const [error, setError] = useState<ErrorBoundaryErrorType | undefined>(
        undefined,
    );

    return (
        <ErrorBoundaryContext.Provider value={{ error, setError }}>
            {children}
        </ErrorBoundaryContext.Provider>
    );
};

export default ErrorBoundaryProvider;
