import { SamplesImportMetaEnv } from 'env';

declare global {
    interface Window {
        SamplesEnvConfig?: string;
    }
}

export const CONFIG: SamplesImportMetaEnv =
    typeof window !== 'undefined' && window.SamplesEnvConfig
        ? window.SamplesEnvConfig
        : JSON.parse(process.env.VITE_ENV_CONF || '');
