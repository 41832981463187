import React from 'react';
import {
    Container,
    Group,
    Box,
    Portal,
    Notification as MaestroNotification,
} from '@landr/maestro';
import { useNotificationsContext } from 'contexts/NotificationsContext/NotificationsContext';
import { NotificationBase } from '../types';
import { useNotification } from './useNotification';
import {
    notificationStyles,
    messageWrapperStyles,
    portalStyles,
} from './styled';

export const Notification = ({
    notification,
}: {
    notification: NotificationBase;
}) => {
    const { variant, message, timeout, handleRemove } =
        useNotification(notification);

    if (!message) {
        return null;
    }

    return (
        <MaestroNotification
            variant={variant}
            kind="temporary"
            onClick={handleRemove}
            onClose={handleRemove}
            timeout={timeout ?? undefined}
            className={notificationStyles}
        >
            <Box className={messageWrapperStyles}>{message}</Box>
        </MaestroNotification>
    );
};

const Notifications: React.FC = () => {
    const { notifications } = useNotificationsContext();

    return (
        <Portal position="fixed" zIndex="alert" className={portalStyles}>
            <Container size="ms">
                <Group
                    direction="vertical"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width="100%"
                    justifyContent="center"
                >
                    {notifications.map((notification) => (
                        <Group.Item key={notification.id}>
                            <Notification notification={notification} />
                        </Group.Item>
                    ))}
                </Group>
            </Container>
        </Portal>
    );
};

export default Notifications;
