import { UntranslatedPlanNames } from '@landr/pricing';

export const PlanCodes: { [k in string]: string } = {
    ['all_access']: UntranslatedPlanNames.LandrStudioStandard,
    ['studio_pro']: UntranslatedPlanNames.LandrStudioPro,
    ['studio_essentials']: UntranslatedPlanNames.LandrStudioEssentials,
    ['synchroarts_artist']: UntranslatedPlanNames.SaSynchroArtsArtist,
    ['synchroarts_professional']:
        UntranslatedPlanNames.SaSynchroArtsProfessional,
};
