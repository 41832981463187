import { Box } from '@landr/maestro';
import React, { PropsWithChildren } from 'react';
import { css } from '../../../../styled-system/css';

const ellipsisStyles = css({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    maxWidth: '100%',
});

export const Shrinkable: React.FC<PropsWithChildren> = ({ children }) => (
    <Box whiteSpace="nowrap" overflow="hidden" maxWidth="100%" flexShrink="1">
        <Box className={ellipsisStyles}>{children}</Box>
    </Box>
);

export const NoShrink: React.FC<PropsWithChildren> = ({ children }) => (
    <Box
        whiteSpace="nowrap"
        overflow="hidden"
        maxWidth="100%"
        flexShrink="0"
        flexGrow="1"
    >
        <Box className={ellipsisStyles}>{children}</Box>
    </Box>
);
