/**
 * `analytics` will provide an instance of the AnalyticsService that will
 * allow for tracking events to be sent to Segment
 */
import { getAnalyticsPlatformName } from 'helpers/executionEnvironment';
import { AnalyticsV2Service } from './AnalyticsV2Service.generated';

const platformName = getAnalyticsPlatformName();

export const analytics = new AnalyticsV2Service(platformName);

export * from './sendAnalyticsIdentify';
