import React, { useContext } from 'react';
import { sendAuthToken } from 'helpers/dawPlugin';
import { getIsDAWPlugin, getIsDesktopApp } from 'helpers/executionEnvironment';
import { AbstractAuthUser } from 'utils/auth/abstractAuth';
import useAuthenticationEffects from './useAuthenticationEffects.hook';

export type AuthenticationContextType = {
    isAuthenticated: boolean;
    isGuestUser: boolean;
    isLoading: boolean;
    user: AbstractAuthUser | null;

    /**
     * In theory, you do not need to use those,
     * Instead, you should be using the auth service directly
     *
     * But we found an issue with an external dependency (our UNB) loosing the `this` object
     * when auth.[method] is props drilled.
     */
    login: () => void;
    signup: () => void;
    logout: () => void;
};

export const defaultAuthenticationContext: AuthenticationContextType = {
    isAuthenticated: false,
    isGuestUser: false,
    isLoading: true,
    user: null,
    login: () => undefined,
    signup: () => undefined,
    logout: () => undefined,
};

export const AuthenticationContext = React.createContext(
    defaultAuthenticationContext,
);

export const useAuthentication = (): AuthenticationContextType =>
    useContext(AuthenticationContext);

interface AuthenticationProviderProps {
    children: React.ReactNode;
}

export const AuthenticationProvider = ({
    children,
}: AuthenticationProviderProps): JSX.Element => {
    const isDAWPlugin = getIsDAWPlugin();
    const isDesktopApp = getIsDesktopApp();

    const {
        isAuthenticated,
        isGuestUser,
        isLoading,
        user,
        login,
        signup,
        logout,
    } = useAuthenticationEffects({ isDAWPlugin, isDesktopApp, sendAuthToken });

    return (
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                isGuestUser,
                isLoading,
                user,
                login,
                signup,
                logout,
            }}
        >
            <>{children}</>
        </AuthenticationContext.Provider>
    );
};
