import { EventIds } from 'constants/eventIds';
import { useCallback } from 'react';
import axios from 'axios';
import { auth } from 'utils/auth';
import { CONFIG } from 'utils/config';
import { didPurchase, getIsDAWPlugin } from 'helpers';
import { download } from 'helpers/download';
import { getDownloadableItemIds } from 'helpers/getDownloadableItemIds';
import { DownloadBinStatusEnum } from '@landr/core.models';
import { DownloadableItem } from 'types';
import { DownloadType } from 'types/enums';
import { useNotificationsContext } from 'contexts/NotificationsContext/NotificationsContext';
import { useSelectedSamplesContext } from 'contexts/SelectedSamplesContext/SelectedSamplesContext';
import { GenerateMixdown } from 'controllers/business/Creator/hooks/useGenerateMixdown';
import { DownloadBinAPIError } from 'types/errors';
import { useApolloClient } from '@apollo/client';
import DownloadsPageQueryConfig from 'apollo/downloads/downloads';
import {
    AddedMultipleToDownloadsSuccess,
    AddedToDownloadsSuccess,
    PreparePurchaseSuccess,
} from 'contexts/NotificationsContext/notifications';
import { PurchaseError } from '../Purchase.types';

export type DownloadHandler = ({
    item,
    url,
}: {
    item: DownloadableItem;
    url: string;
}) => Promise<void>;

const FalsyResponseError = new Error('Download Falsy Response');
const DelayMS =
    Number.parseInt(CONFIG.VITE_POLLING_INTERVAL as string, 10) || 4000;

const PreparingZipNotificationId = 'preparing-multi-download-zip-notification';

export const useHandleDownload = ({
    handleError,
    generateMixdown,
}: {
    generateMixdown: GenerateMixdown;
    handleError: (error?: PurchaseError) => void;
}): DownloadHandler => {
    const { clearSamplesSelection } = useSelectedSamplesContext();
    const apolloClient = useApolloClient();
    const { removeNotification, createNotification } =
        useNotificationsContext();

    const prepareDownloadNotification = useCallback(() => {
        createNotification(
            new PreparePurchaseSuccess(PreparingZipNotificationId),
        );
    }, [createNotification]);

    const downloadSucceededNotification = useCallback(
        (item: DownloadableItem) => {
            // Multiple download will render this notification
            removeNotification(PreparingZipNotificationId);
            const { isDownloaded, name } = item;

            if (isDownloaded) {
                return;
            }

            if (item.itemIds.length > 1) {
                createNotification(
                    new AddedMultipleToDownloadsSuccess(item.itemIds.length),
                );
            } else {
                createNotification(new AddedToDownloadsSuccess(name));
            }
        },
        [createNotification, removeNotification],
    );

    const startDownload = useCallback(
        async ({
            item,
            downloadUrl,
        }: {
            item: DownloadableItem;
            downloadUrl: string;
        }) => {
            const isDawPlugin = getIsDAWPlugin();
            const isCreatorType =
                item.type === DownloadType.Creator ||
                item.type === DownloadType.CreatorMulti;
            const isInvalidTypeForDawPlugin = isDawPlugin && isCreatorType;

            const storeKeys = Object.keys(
                (apolloClient.cache as any)?.data?.data?.ROOT_QUERY,
            );

            storeKeys?.forEach((key) => {
                if (
                    !key.includes(DownloadsPageQueryConfig.packs.pathToItems) &&
                    !key.includes(DownloadsPageQueryConfig.samples.pathToItems)
                ) {
                    return;
                }

                apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: key });
            });

            apolloClient.cache.gc();

            if (isInvalidTypeForDawPlugin) {
                handleError({
                    message: 'Download Creator Item Within DAW',
                    id: EventIds.DownloadCreatorItemWithinDaw,
                    error: new Error(`Download Creator Item Within DAW`),
                });
            } else if (isDawPlugin) {
                const ids = getDownloadableItemIds(item);

                ids.forEach((id) => didPurchase(id));
            } else if (item.type === DownloadType.CreatorMixdown) {
                await generateMixdown(true);
            } else {
                download(downloadUrl);
            }
        },
        [generateMixdown, handleError, apolloClient],
    );

    const finalizeMultiDownloadPolling = useCallback(
        (interval: NodeJS.Timeout, error?: PurchaseError) => {
            clearSamplesSelection();
            clearInterval(interval);

            // Note: when no error, we exit purchase flow with startDownload callback
            if (error) {
                removeNotification(PreparingZipNotificationId);
                handleError(error);
            }
        },
        [clearSamplesSelection, handleError, removeNotification],
    );

    const singleStartDownload = useCallback(
        ({
            item,
            downloadUrl,
        }: {
            item: DownloadableItem;
            downloadUrl: string;
        }) => {
            startDownload({ item, downloadUrl });
            downloadSucceededNotification(item);
        },
        [startDownload, downloadSucceededNotification],
    );

    const startMultiDownloadPolling = useCallback(
        async (item: DownloadableItem, downloadUrl: string) => {
            const accessToken = await auth.getAccessToken();

            const interval = setInterval(() => {
                axios
                    .get(downloadUrl, {
                        headers: {
                            Authorization: `Bearer ${accessToken}`, // eslint-disable-line
                            'Content-Type': 'application/json', // eslint-disable-line
                        },
                    })
                    .then((res) => {
                        if (res) {
                            switch (res.data.status) {
                                case DownloadBinStatusEnum.InProgress:
                                    // If ZIP creation is in progress, return null and wait for next data.status.
                                    return;
                                case DownloadBinStatusEnum.Succeeded:
                                    if (res.data.downloadUri) {
                                        // Once ZIP creation is complete, stop polling and proceed with the download.
                                        startDownload({
                                            item,
                                            downloadUrl: res.data.downloadUri,
                                        });
                                        finalizeMultiDownloadPolling(interval);
                                        downloadSucceededNotification(item);
                                    }

                                    break;
                                case DownloadBinStatusEnum.Failed:
                                default:
                                    // When ZIP can't be created, polling still succeeds. We have to manually generate an error.
                                    finalizeMultiDownloadPolling(interval, {
                                        message:
                                            'Multi download ZIP creation failed',
                                        id: EventIds.DownloadBinZipFailed,
                                        error: new DownloadBinAPIError(
                                            res.data,
                                        ),
                                    });
                                    break;
                            }
                        } else {
                            // Should not occur unless there is a network problem
                            finalizeMultiDownloadPolling(interval, {
                                message: 'Falsy response',
                                id: EventIds.DownloadBinFalsyResponse,
                                error: FalsyResponseError,
                            });
                        }
                    })
                    .catch((error: unknown) => {
                        // Should not occur unless there is a network problem
                        finalizeMultiDownloadPolling(interval, {
                            message: 'Network problem',
                            id: EventIds.DownloadBinNetworkProblem,
                            error: error as Error,
                        });
                    });
            }, DelayMS);
        },
        [
            finalizeMultiDownloadPolling,
            startDownload,
            downloadSucceededNotification,
        ],
    );

    const downloadHandler = async ({
        item,
        url,
    }: {
        item: DownloadableItem;
        url: string;
    }) => {
        const isMultiDownloadCall = [
            DownloadType.Multi,
            DownloadType.Creator,
            DownloadType.CreatorMulti,
            DownloadType.CollectionMulti,
        ].includes(item.type);

        if (isMultiDownloadCall || item.type === DownloadType.CreatorMixdown) {
            prepareDownloadNotification();
        }

        if (isMultiDownloadCall) {
            startMultiDownloadPolling(item, url);
        } else {
            singleStartDownload({ item, downloadUrl: url });
        }
    };

    return downloadHandler;
};
