import React from 'react';
import type { PlayerProviderProps } from '@landr/react-player/dist/contexts/PlayerProvider/PlayerProvider';
import { PlayerProvider } from '@landr/react-player';
import { log } from 'utils/log';

export const ReactPlayerProvider = ({
    children,
}: {
    children: React.ReactNode;
}): React.ReactElement => {
    const playerProviderProps: PlayerProviderProps & {
        children: React.ReactNode;
    } = {
        log,
        children,
    };

    return <PlayerProvider {...playerProviderProps} />;
};

export default ReactPlayerProvider;
